.otpInputStyleCssPopup input{
    width: 70px !important;
    height: 55px !important;
    text-align: center;
    margin-right: 30px !important;
    border-right: none;
    border-left: none;
    border-top: none;
    border-bottom:  1px solid;
}
.otpInputStyle input{
  width: 48px !important;
  height: 48px !important;
  text-align: center;
  margin-right: 30px !important;
  border-right: 1px solid #D8D6DE;
  border-left: 1px solid #D8D6DE;
  border-top: 1px solid #D8D6DE;
  border-bottom:  1px solid #D8D6DE;
  border-radius: 5px;
  color:"black"
}
.otpInputRegisterForm input{
  width: 30px !important;
  height: 30px !important;
  text-align: center;
  margin-right: 4px !important;
  border-right: 1px solid #D8D6DE;
  border-left: 1px solid #D8D6DE;
  border-top: 1px solid #D8D6DE;
  border-bottom:  1px solid #D8D6DE;
  border-radius: 5px;
  color:"black"
}

.customButtonLoginPopup{
    background: linear-gradient(181.67deg, #47B59C 1.42%, #3963AB 318.41%);
    border-radius: 3px;
  }

  .link_change_phone{
    color: #3963AB;
    text-decoration: underline;
    font-size: 14px;
  }

  .link_change_phone:hover{
    cursor: pointer;
  }
  

@media only screen and (max-width: 600px) {
    .otpInputStyleCss input {
        width: 55px !important;
        height: 40px !important;
        margin-left: 5px;
    }
  }