 *{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Poppins', 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Roboto', 'Poppins', 'Courier New',
    monospace;
}

.link_comman{
  color: blue;
  font-style: italic;
  text-decoration: underline;
}

.link_comman:hover{
cursor: pointer;
} 

/* In your CSS file */
.remove-quill-padding .ql-container {
  padding: 2px !important;
}

.remove-quill-padding .ql-editor {
  padding: 2px !important;
}


/* custom.css or index.css */
.homepage-scrollbar-hide {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}

.homepage-scrollbar-hide::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

/* ::-webkit-scrollbar {
  display: none;
} */

/* .Homepage-scrollbar-hidden ::-webkit-scrollbar {
  display: none;
} */