.otpInputStyleCss input{
    width: 70px !important;
    height: 55px !important;
    text-align: center;
    margin-right: 30px !important;
    border: 0.1px solid #00000075;
    border-radius: 5px;
}

.otpInputStyleCssNew input{
    width: 50px !important;
    height: 40px !important;
    text-align: center;
    margin-right: 15px !important;
    border: 0.1px solid #00000075;
    border-radius: 5px;
    background-color: "#000000" !important;
    color: "#000000" !important;
}

@media only screen and (max-width: 600px) {
    .otpInputStyleCss input {
        width: 55px !important;
        height: 40px !important;
        margin-left: 5px;
    }
  }