@media only screen and (min-width: 300px) {
  #paperDivnew {
   
    min-width: 280px;
  }
}


@media only screen and (min-width: 400px) {
    #paperDivnew {
     
      min-width: 360px;
    }
  }

@media only screen and (min-width: 600px) {
    #paperDivnew {
     
      min-width: 520px;
    }
  }
@media only screen and (min-width: 700px) {
    #paperDivnew {
     
      min-width: 650px;
    }
    .global {
      min-width: 500px !important;
    }
  }

