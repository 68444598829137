@font-face {
    font-family: 'PP Telegraf';
    src:local('PP Telegraf'),
     url('./fonts/PPTelegraf-Regular.otf') format('opentype');

    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: 'Akshar Unicode';
    src:local('Akshar Unicode'),
     url('./fonts/AksharUnicodeRegular.ttf') format('opentype');

    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'trade-gothic-lt';
    src:local('Trade Gothic lt'), url('./fonts/tradeGothiclt.ttf') format('truetype');
  }

  @font-face {
    font-family: 'rajdhani';
    src:local('rajdhani'), url('./fonts/Rajdhani-Bold.ttf') format('truetype');
  }

  @font-face {
    font-family: 'goldman_sans';
    src:local('goldman_sans'), url('./fonts/GoldmanSans_Rg.ttf') format('truetype');
  }