.react-datepicker {
  background-color: #2d2d2d;
  border: 1px solid #4a4a4a;
  color: #ffffff;
}

.react-datepicker__header {
  background-color: #2d2d2d;
  border-bottom: 1px solid #4a4a4a;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #ffffff;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #4a4a4a;
}

.react-datepicker__day--today {
  background-color: #3a3a3a;
  color: #ffffff;
}

/* Add hover effect */
.react-datepicker__day:hover {
  background-color: #ffffff;
  color: #000000;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--keyboard-selected:hover {
  background-color: #ffffff;
  color: #000000;
}
